'use client';

import { useState } from 'react';
import NewsletterSignup from '@/components/newsletter-signup/NewsletterSignup';
import { ContentfulFlexiblePageBlock, ContentfulStream, ContentfulStreamSecurity } from '@/types/contentful';
import PreviewPageContent from './PreviewPageContent';
import PreviewPageSubmitBlock from './PreviewPageSubmitBlock';
const PreviewPage = ({
  location,
  blocks,
  slug,
  streams,
  accessCode,
  streamSecurity
}: {
  location?: Location;
  blocks?: ContentfulFlexiblePageBlock[];
  streams: ContentfulStream[];
  accessCode: string;
  slug: string;
  streamSecurity: ContentfulStreamSecurity;
}) => {
  const [hasAccess, setHasAccess] = useState(false);
  return <>
      {hasAccess ? <PreviewPageContent blocks={blocks} streams={streams} streamSecurity={streamSecurity} accessCode={accessCode} slug={slug} /> : <PreviewPageSubmitBlock accessCode={accessCode} setHasAccess={hasAccessValue => setHasAccess(hasAccessValue)} />}
      <NewsletterSignup data-sentry-element="NewsletterSignup" data-sentry-source-file="index.tsx" />
    </>;
};
export default PreviewPage;