'use client';

import MyPlace from '../../my-place/MyPlace';
import PrivateRoute from '../../PrivateRoute';
const MyPlacePage = () => {
  return <section className="container" style={{
    paddingTop: 90,
    marginBottom: 200,
    maxWidth: '100rem'
  }} data-sentry-component="MyPlacePage" data-sentry-source-file="index.tsx">
      <MyPlace data-sentry-element="MyPlace" data-sentry-source-file="index.tsx" />
    </section>;
};
export default ({
  pageContext
}: {
  pageContext: any;
}) =>
//@ts-ignore
<PrivateRoute component={MyPlacePage} pageContext={pageContext} />;