import { CalendarOptions } from '@/types/calendar';
import { AddToCalendarEvent, createAddToCalendarUrl } from '../../../utils/add-to-calendar';
import { AppleLogo, GoogleLogo, Office365Logo, OutlookComLogo, OutlookLogo, YahooLogo } from '../../svgs';
interface CalendarOptionProps {
  option: CalendarOptions;
  config: AddToCalendarEvent;
  sku?: string;
}
const optionIcon = (option: CalendarOptions) => {
  switch (option) {
    case 'Apple':
      return <AppleLogo />;
    case 'Google':
      return <GoogleLogo />;
    case 'Office 365':
      return <Office365Logo />;
    case 'Outlook':
      return <OutlookLogo />;
    case 'Outlook.com':
      return <OutlookComLogo />;
    case 'Yahoo':
      return <YahooLogo />;
    default:
      return <></>;
  }
};
export const CalendarOption = ({
  option,
  config,
  sku
}: CalendarOptionProps): JSX.Element => <button type="button" key={option} onClick={() => {
  createAddToCalendarUrl({
    platform: option,
    event: config
  });
}} className="d-flex align-items-center m-0 body-text option" data-sentry-component="CalendarOption" data-sentry-source-file="CalendarOption.tsx">
    <div className="d-flex justify-content-center option__icon pe-1">
      {optionIcon(option)}
    </div>
    <span>{option}</span>
  </button>;