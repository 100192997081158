'use client';

import ROUTES from '../../../constants/routes';
import ConnectForm from '../../connect';
import PrivateRoute from '../../PrivateRoute';
const ConnectPage = () => {
  return <ConnectForm data-sentry-element="ConnectForm" data-sentry-component="ConnectPage" data-sentry-source-file="index.tsx" />;
};
const returnToUrl = typeof window !== 'undefined' ? `${ROUTES.connect}${window?.location?.search}` : undefined;

//@ts-ignore
export default ({
  pageContext
}: {
  pageContext: any;
}) => <PrivateRoute
//@ts-ignore
component={ConnectPage} pageContext={pageContext} returnTo={returnToUrl} />;