'use client';

import { useParams } from 'next/navigation';
import ChangePasswordForm from '../../change-password';
const ChangePassword = () => {
  const params = useParams();
  const userId = params?.id?.[1];
  const resetToken = params?.id?.[2];
  if (!userId || !resetToken) return null;
  return <div className="container" style={{
    marginTop: 200,
    marginBottom: 200
  }} data-sentry-component="ChangePassword" data-sentry-source-file="index.tsx">
      <ChangePasswordForm userId={userId} resetToken={resetToken} data-sentry-element="ChangePasswordForm" data-sentry-source-file="index.tsx" />
    </div>;
};
export default ChangePassword;