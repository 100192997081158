import Script from 'next/script';
const EventSchema = ({
  prerendered
}: {
  prerendered: string | undefined;
}) => {
  return <Script id="prerendered" type="application/ld+json" strategy="lazyOnload" data-sentry-element="Script" data-sentry-component="EventSchema" data-sentry-source-file="event-schema.tsx">
      {JSON.stringify(prerendered)}
    </Script>;
};
export default EventSchema;