'use client';

import { ChangeEvent, useCallback, useState } from 'react';
import * as EmailValidator from 'email-validator';
import { useAppSelector } from '@/state/hooks';
import { pushToElevarDataLayer } from '@/utils/pushToElevarDataLayer';
import { getEnvironmentVariableServerSide } from '@/utils/server-functions/getEnvServerSide';
import { NewsletterInput } from './NewsletterInput';
import { NewsletterSuccess } from './NewsletterSucces';
import { NewsletterSignupStatus } from './types';
const NewsletterSignup = () => {
  const [loading, setLoading] = useState(false);
  const [validationError, setValidationError] = useState(false);
  const [signUpStatus, setSignUpStatus] = useState<NewsletterSignupStatus | undefined>(undefined);
  const [emailValue, setEmailValue] = useState('');
  const auth = useAppSelector(state => state.auth);
  const loggedIn = useAppSelector(state => state.auth.loginStatus === 'LOGGED_IN');
  const onChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    if (validationError) setValidationError(false);
    setEmailValue(e.target.value);
  };
  const subscribe = useCallback(async () => {
    setLoading(true);
    if (emailValue && EmailValidator.validate(emailValue)) {
      const formData = new FormData();
      formData.append('EMAIL', emailValue);
      try {
        pushToElevarDataLayer({
          eventName: 'dl_subscribe',
          lead_type: 'email',
          auth: auth.token,
          email: emailValue
        });
        // Fetch does not use cookie by default, so any 3rd party cookies will not be saved
        const {
          SENDINBLUE_ENDPOINT
        } = await getEnvironmentVariableServerSide(['SENDINBLUE_ENDPOINT']);
        const response = await fetch(SENDINBLUE_ENDPOINT!, {
          method: 'POST',
          body: formData,
          cache: 'no-store'
        });
        // Only show a success notification if 200 and success: true are returned
        const {
          success
        } = await response.json();
        setSignUpStatus(!response.ok || !success ? 'error' : 'success');
      } catch (error) {
        setSignUpStatus('error');
      } finally {
        setLoading(false);
      }
    } else {
      setValidationError(true);
      setLoading(false);
    }
  }, [setSignUpStatus, setValidationError, emailValue]);
  if (loggedIn) return null;
  return <div className="py-4 py-md-7 newsletter-signup" data-sentry-component="NewsletterSignup" data-sentry-source-file="NewsletterSignup.tsx">
      <div className="container">
        <div className="d-flex justify-content-between d-row">
          <div className="m-md-auto text-center col-lg-10 col-md-12 col-xl-8 column">
            {signUpStatus ? <div className="flex-grow-1 row">
                <NewsletterSuccess />
              </div> : <NewsletterInput subscribe={subscribe} onChangeInput={onChangeInput} loading={loading} validationError={validationError} />}
          </div>
        </div>
      </div>
    </div>;
};
export default NewsletterSignup;