type AcceptValue = 'on' | 'off';

export type PreSerializedRegisterFormData = {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  acceptsMarketing: AcceptValue;
  acceptsTermsAndConditions: AcceptValue;
  day: string;
  month: string;
  year: string;
};

export type SerializedRegisterFormData = {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  acceptsMarketing: boolean;
  acceptsTermsAndConditions: boolean;
  day: string;
  month: string;
  year: string;
};

export enum RequestStatus {
  IDLE = 'IDLE',
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}
