/* eslint-disable no-underscore-dangle */
import React from 'react';
import type { FC } from 'react';
import AccordionBlock from '@/components/accordion-block';
import ContentBlock from '@/components/content-block';
import { ContentfulFlexiblePageBlock } from '@/types/contentful';
import type { EventsByTag } from '@/types/page-context';
import EventCarousel from '../../event-carousel';
import Gallery from '../../gallery';
import { HighlightsBlock } from '../../highlights-block';
import SellingPointsBlock from '../../selling-point';
import { SpotifyPlayerBlock } from '../../spotify-player';
import TestimonialBlock from '../../testimonial-block';
import VideoBlock from '../../video-block';
const FlexiblePageBlock: FC<{
  block: ContentfulFlexiblePageBlock;
  eventsByTag?: EventsByTag;
  showTransparentBackground: boolean;
  isFirstBlock: boolean;
}> = ({
  block,
  showTransparentBackground,
  isFirstBlock
}) => {
  return <>
      {block.__type === 'contentBlock' && <ContentBlock contentBlock={block} isFirstBlock={isFirstBlock} showTransparentBackground={showTransparentBackground} />}
      {block.__type === 'sellingPointsSection' && <SellingPointsBlock sellingPointsBlock={block} isFirstBlock={isFirstBlock} />}
      {block.__type === 'galleryBlock' && <Gallery gallery={block} isFirstBlock={isFirstBlock} />}
      {block.__type === 'testimonialBlock' && <TestimonialBlock block={block} isFirstBlock={isFirstBlock} />}
      {block.__type === 'eventCarouselBlock' && <EventCarousel carousel={block} isFirstBlock={isFirstBlock} />}
      {block.__type === 'videoBlock' && <VideoBlock youtubeLink={block.youtubeLink} autoPlay={false} selfHostedVideo={block.selfHostedVideo} isFirstBlock={isFirstBlock} />}
      {block.__type === 'spotifyPlaylist' && <SpotifyPlayerBlock block={block}
    // @ts-expect-error
    embeddedPlaylist={block.embeddedPlaylist} callToActionButton={block.callToActionButton} title={block.title} text={block.text} isFirstBlock={isFirstBlock} />}
      {block.__type === 'highlightsBlock' && <HighlightsBlock highlights={block.highlights} showTransparentBackground={showTransparentBackground} isFirstBlock={isFirstBlock} />}
      {block.__type === 'accordionBlock' && <AccordionBlock block={block} isFirstBlock={isFirstBlock} />}
    </>;
};
export default FlexiblePageBlock;