import { FC } from 'react';
import { BUY_BUTTON_ID } from '@/constants/common';
import { EventVariant } from '@/types/contentful';
import { formatDisplayPrice, getCountryCurrency } from '@/utils/i18n';
import { useCountryCodeSelector } from '../../state/hooks';
import Button from '../Button';
import messages from './messages';
type HeroButtonAndPriceProps = {
  readonly selectedVariant: EventVariant;
  readonly handleBuyTicketNow?: () => void;
  readonly isLoading?: boolean;
};
const HeroButtonAndPrice: FC<HeroButtonAndPriceProps> = ({
  selectedVariant,
  handleBuyTicketNow,
  isLoading
}) => {
  const customerCountryCode = useCountryCodeSelector();
  const customerCurrency = getCountryCurrency(customerCountryCode);
  const compareAtPrice =
  //@ts-ignore
  selectedVariant?.compareAtPrice?.[customerCurrency.toLowerCase()];
  return <div className="event-configurator__button-and-price" data-sentry-component="HeroButtonAndPrice" data-sentry-source-file="HeroButtonAndPrice.tsx">
      <h4 className="event-configurator__price" data-testid="event-configurator__price">
        {selectedVariant?.compareAtPrice && compareAtPrice && <span className="event-configurator__before-price">
            <i>
              {formatDisplayPrice(customerCountryCode, selectedVariant?.compareAtPrice)}
            </i>{' '}
          </span>}
        <span>
          {selectedVariant.price && formatDisplayPrice(customerCountryCode, selectedVariant.price)}
        </span>
      </h4>
      <div className="event-configurator__buy-button-wrapper">
        <Button icon="oa-ticket" grow onClick={handleBuyTicketNow} loading={isLoading} id={BUY_BUTTON_ID} data-sentry-element="Button" data-sentry-source-file="HeroButtonAndPrice.tsx">
          {messages.buyShowNow}
        </Button>
      </div>
    </div>;
};
export default HeroButtonAndPrice;