import React, { MouseEvent, ReactNode } from 'react';
import { ButtonType } from '@/enums/ButtonType';
import { Popover, PopoverContent, PopoverTrigger } from '../-core/popover';
import Button from '../Button';
interface BaseProps {
  children: ReactNode;
  text: string;
  title?: string;
  side?: 'top' | 'right' | 'bottom' | 'left';
}
interface PropsWithoutButtons extends BaseProps {
  defaultOpen?: boolean;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
  buttons?: never;
}
interface PropsWithButtons extends BaseProps {
  defaultOpen?: never;
  open: boolean;
  onOpenChange?: never;
  buttons: {
    left: ButtonProps;
    right: ButtonProps;
  };
}
interface ButtonProps {
  text: string;
  onClick: (event?: MouseEvent<Element>) => void;
}
const PopoverWrapper = ({
  children,
  text,
  title,
  side,
  buttons,
  defaultOpen,
  open,
  onOpenChange
}: PropsWithButtons | PropsWithoutButtons) => {
  const handleOpenChange = (open: boolean) => {
    if (buttons) {
      // When there are buttons the open state should only be handled by the buttons and not the component itself
      return;
    }
    onOpenChange?.(open);
  };
  return <Popover defaultOpen={defaultOpen} open={open} onOpenChange={onOpenChange || buttons ? handleOpenChange : undefined} data-sentry-element="Popover" data-sentry-component="PopoverWrapper" data-sentry-source-file="index.tsx">
      <PopoverTrigger className="popover-trigger" data-sentry-element="PopoverTrigger" data-sentry-source-file="index.tsx">{children}</PopoverTrigger>
      <PopoverContent className="popover" collisionPadding={16} side={side} data-sentry-element="PopoverContent" data-sentry-source-file="index.tsx">
        {title && <div className="popover__title">{title}</div>}
        {text}
        {buttons && <div className="popover__buttons">
            <Button type={ButtonType.LINK} onClick={buttons.left.onClick}>
              {buttons.left.text}
            </Button>
            <Button onClick={buttons.right.onClick}>
              {buttons.right.text}
            </Button>
          </div>}
      </PopoverContent>
    </Popover>;
};
export default PopoverWrapper;