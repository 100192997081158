'use client';

import type { FC } from 'react';
import { EventCountdownBanner } from '@/components/banner';
import EventCarousel from '@/components/event-carousel';
import { ContentfulImage, Event, EventBlock, EventStatus } from '@/types/contentful';
import { EventPartner } from '@/types/contentful/event';
import type { CodedError } from '../../../types/error';
import type { EventsByTag, OGP } from '../../../types/page-context';
import Hero from '../../hero/HeroComponent';
import type { THEOplayerError } from '../../theo-player/types';
import EventSchema from './event-schema';
import EventBlockComponent from './EventBlockComponent';
import HeroWithGeoIpAccess from './HeroWithGeoIpAccess';
import NotForSale from './NotForSale';
import OnlyForSaleWithPartner from './OnlyForSaleWithPartner';
interface Props {
  event: Event;
  eventsByTag: EventsByTag;
  selectedVariantSku?: string;
  isShowRestrictedInCurrentCountry?: boolean;
  partnerToAccessShowInCurrentCountry?: EventPartner;
  // Player and Hero related properties
  eventStatus?: EventStatus;
  doorsOpenDate?: Date;
  eventStart?: Date;
  duration?: number;
  isSaleOpen?: boolean;
  showCountdownBanner?: boolean;
  countDownBannerInfo?: {
    playingNow: boolean;
    countDownTime: Date;
  };
  showEventConfigurator?: boolean;
  showPlayer?: boolean;
  showPlayerLoader?: boolean;
  showError?: boolean;
  showPlaceholder?: boolean;
  onPlayerError?: (error: THEOplayerError) => void;
  error?: CodedError;
  hasTicketInShopify?: boolean;
  hasAccess?: boolean;
  loggedIn?: boolean;
  isWatchTrailerParamSet?: boolean;
  blocks?: EventBlock[];
  stream?: {
    dashUrl?: string;
    hlsUrl?: string;
    dashCastUrl?: string;
  };
  ogp?: OGP;
  schemaData?: {
    prerendered?: string;
  };
  backgroundImageProps: any;
  trackingTitle?: string;
  shopifyUserId?: string;
  streamUrlsError?: boolean;
  location?: Location;
  jwt?: string;
}
const EventPageComponent: FC<Props> = ({
  event,
  eventsByTag,
  selectedVariantSku,
  isShowRestrictedInCurrentCountry,
  partnerToAccessShowInCurrentCountry,
  isWatchTrailerParamSet,
  blocks,
  duration,
  showPlaceholder,
  loggedIn = false,
  showCountdownBanner = false,
  countDownBannerInfo,
  showPlayer = false,
  showEventConfigurator = false,
  showPlayerLoader = false,
  showError = false,
  error,
  onPlayerError,
  hasTicketInShopify = false,
  hasAccess = false,
  isSaleOpen = false,
  eventStatus,
  doorsOpenDate,
  eventStart,
  stream,
  ogp,
  schemaData,
  backgroundImageProps,
  trackingTitle,
  shopifyUserId,
  streamUrlsError,
  location,
  jwt
}) => {
  return <>
      {showCountdownBanner && countDownBannerInfo && <EventCountdownBanner startDate={countDownBannerInfo.countDownTime} playingNow={countDownBannerInfo.playingNow} />}
      {isShowRestrictedInCurrentCountry && !hasTicketInShopify ? <Hero backgroundImageProps={backgroundImageProps} colClassNames="col-12 col-md-8 col-lg-7 col-xl-5" isCentered isEventHero>
          {!partnerToAccessShowInCurrentCountry?.partnerName && !partnerToAccessShowInCurrentCountry?.partnerLink ? <NotForSale image={event.eventInfoLogo as ContentfulImage} /> : <OnlyForSaleWithPartner image={event.eventInfoLogo as ContentfulImage} partnerName={partnerToAccessShowInCurrentCountry?.partnerName} partnerLink={partnerToAccessShowInCurrentCountry?.partnerLink} />}
        </Hero> : <HeroWithGeoIpAccess event={event} selectedVariantSku={selectedVariantSku} eventStatus={eventStatus} doorsOpenDate={doorsOpenDate} eventStart={eventStart} duration={duration} isSaleOpen={isSaleOpen} showEventConfigurator={showEventConfigurator} showPlayer={showPlayer} showPlayerLoader={showPlayerLoader} showError={showError} showPlaceholder={showPlaceholder} onPlayerError={onPlayerError} error={error} hasTicketInShopify={hasTicketInShopify} hasAccess={hasAccess} loggedIn={loggedIn} stream={stream} backgroundImageProps={backgroundImageProps} trackingTitle={trackingTitle} shopifyUserId={shopifyUserId} streamUrlsError={streamUrlsError} jwt={jwt} />}

      {blocks?.map((block, index) => <EventBlockComponent key={`block${index}`} isFirstBlock={index === 0 && !showPlayer} block={block} event={event} eventsByTag={eventsByTag} showTransparentBackground={blocks.length > 1 || index % 2 !== 0} autoPlayVideo={isWatchTrailerParamSet && blocks.find(({
      __type
    }) => __type === 'videoBlock') === block} />)}

      {event.relatedShows && <EventCarousel carousel={event.relatedShows} />}

      <EventSchema prerendered={schemaData?.prerendered} data-sentry-element="EventSchema" data-sentry-source-file="EventPageComponent.tsx" />
    </>;
};
export default EventPageComponent;